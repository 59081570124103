<script>
import NavBarLink from "./NavBarLink.vue";
import NavBarDropDown from "./NavBarDropDown.vue";
import { useRequestStore } from "@/stores/requestStore";
export default {
  props: ["config"],
  components: {
    NavBarLink,
    NavBarDropDown,
  },
  setup() {
    const requestStore = useRequestStore();

    const resetMap = () => {
      requestStore.$reset();
    };

    return { requestStore, resetMap };
  },
};
</script>

<template>
  <div class="row">
    <nav class="navbar nav justify-content-md-start">
      <ul class="nav">
        <AppLink
          @click="resetMap"
          class="nav-link text-dark text-decoration-none"
          :to="{ name: 'request' }"
        >
          <i class="bi bi-fire pe-1"></i>New Req<span class="d-none d-md-inline"
            >uest</span
          >
        </AppLink>
        <component
          v-for="(item, index) in config"
          :is="item.type"
          :key="index"
          :name="item.name"
          :items="item?.content"
          :href="item?.href"
          :icon="item?.icon"
        >
        </component>
      </ul>
    </nav>
  </div>
</template>

<style scoped>
@media screen and (min-width: 50em) {
  nav {
    font-size: 1em;
  }
}
</style>
