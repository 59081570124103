<script>
export default {
  props: {
    name: String,
    href: String,
    icon: String,
  },
};
</script>
<template>
  <li class="nav-item">
    <AppLink class="nav-link text-body" aria-current="page" :to="this.href">
      <i v-if="this.icon" :class="this.icon"></i>{{ `${this.name}` }}
    </AppLink>
  </li>
</template>
