import { useErrorInfoStore } from "@/stores/errorInfoStore";
import { useRouter } from "vue-router";
import { toRaw } from 'vue';

const defaultOptionsPost = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: API_KEY, // eslint-disable-line no-undef
  },
};

const defaultOptionsGET = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: API_KEY, // eslint-disable-line no-undef
  },
};

const defaultOptionsPUT = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: API_KEY, // eslint-disable-line no-undef
  },
};
const defaultOptionsDelete = {
  method: "DELETE",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: API_KEY, // eslint-disable-line no-undef
  },
};

const apiWeatherOptionsGet = {
  headers: {
    Accept:
      "text/ html, application/xhtml+xml,application/xml; q = 0.9, image / avif, image / webp,*/*;q=0.8",
  },
};

export async function postSTQRequest(data) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  const options = {
    ...defaultOptionsPost,
    body: JSON.stringify(data),
  };
  try {
    console.debug(`postSTQRequest: ${options.body}`);
    const response = await fetch(`${API_URL}/requests`, options); // eslint-disable-line no-undef
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to create new SPOT request with
        the API. Please submit a ticket including the response code and text
        below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function patchSTQRequest(data) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  const options = {
    ...defaultOptionsPUT,
    body: JSON.stringify(data),
  };
  try {
    console.debug(`patchSTQRequest: ${options.body}`);
    const response = await fetch(`${API_URL}/requests/${data.id}`, options); // eslint-disable-line no-undef
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to update the SPOT request with
        the API. Please submit a ticket including the response code and text
        below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getLiveRequestsbyWFO(officeNativeSite="") {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(`getLiveRequestsbyWFO`);

    const response = await fetch(
        `${API_URL}/requests?office.nativeSiteId=${officeNativeSite}&isArchived=false`, // eslint-disable-line no-undef
        defaultOptionsGET
      );

    if ( [401, 403, 405, 408, 500, 502, 503, 504].includes(response.status)) {
      errorInfoStore.generalMessage = `Unable to get current SPOT requests by WFO from
        the API. Please submit a ticket including the response code and text
        below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }

    return response; 

  } catch (err) {
    console.error(err.message);
  }
}

export async function getLiveRequests(officeId = "") {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(`getLiveRequests`);
    const response = await fetch(
      `${API_URL}/requests?office.id=${officeId}&isArchived=false`, // eslint-disable-line no-undef
      defaultOptionsGET
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to get current SPOT requests from
        the API. Please submit a ticket including the response code and text
        below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getLiveRequest(id, token) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();

  /*
   * A token value is passed through the edit page. All other pages that use
   * this method only require the standard API key.
   */
  if (token == undefined) {
    token = API_KEY; // eslint-disable-line no-undef
  }

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/ld+json",
      Authorization: token,
    },
  };
  try {
    console.debug(`getLiveRequest`);
    const response = await fetch(
      `${API_URL}/request/edit-request/${id}`, // eslint-disable-line no-undef
      options
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to get the SPOT request from the
        API for id ${id}. Please submit a ticket including the response code and
        text below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function editLiveRequest(data, spotId, requestToken) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/ld+json",
      Accept: "application/ld+json",
      Authorization: requestToken,
    },
    body: JSON.stringify(data),
  };
  try {
    console.debug(`editLiveRequest`);
    const response = await fetch(`${API_URL}/requests/${spotId}`, options); // eslint-disable-line no-undef
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to edit the SPOT request with the
        id ${spotId}. The API returned an error. Please submit a ticket
        including the response code and text below as well as the current time
        ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function deleteRequest(id) {
  const response = await fetch(
    `${API_URL}/requests/${id}`, // eslint-disable-line no-undef
    defaultOptionsDelete
  );
  if (response.status == 200) {
    return await response.json();
  }
}

export async function getIncidents() {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  let response = null;
  try {
    console.debug(`getIncidents`);
    response = await fetch(`${API_URL}/incidents`, defaultOptionsGET); // eslint-disable-line no-undef
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to get the incidents from the API.
        Please submit a ticket including the response code and text below as
        well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

/*
Params:
  lon: string
  lat: string
  api: string - Defienes which api service to use.
Returns:
  - JSON object if APIs' "features" is not an empty array, or
  - Null if "features" array is empty.
Note: when property "features" is empty it means that the API
used does not contains information about that geographic point.
*/
async function getWfoNoaaApis(lon, lat, api="apiweather"){
  let response;
  let metadata;

  switch(api){
    case "apiweather":
      try{
        // console.info("Using api.weather.gov");
        response = await fetch(
          `${WEATHER_URL}/zones?point=${lat},${lon}`, // eslint-disable-line no-undef
          apiWeatherOptionsGet);
        break;
      } catch (err){
        console.error("Unable to get forecast office from api.wather.gov");
        return null;
      }
    case "mapservices":
      try{
        // console.info("Using mapservices.weather.noaa.gov");
        response = await fetch(
          `${MAPSERVICES_URL}/static/rest/services/nws_reference_maps/nws_reference_map/FeatureServer/10/query?geometry=${lon},${lat}&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&outFields=WFO&returnGeometry=false&outSR=4326&f=pjson`, // eslint-disable-line no-undef
          apiWeatherOptionsGet);
        break;
      } catch (err){
        console.error("Unable to get forecast office from mapservices.weather.noaa.gov");
        return null;
      }
    default:
        console.error(`At getWfoNoaaApis option "${api}" passed is not valid`);
        return null;
  }

  try {
    if (response.status == 200) {
      metadata = await response.json();
      if (metadata.features.length == 0 ){
        return null;
      }
      return metadata;
    }
  } catch (err){
    console.error(err.message);
    return null;
  }
}

export async function getForecastOffice(lat, lon, api) {
  console.info(`Get Forecast Office at lon,lat = ${lon},${lat}`);
  // const errorInfoStore = useErrorInfoStore();
  // const router = useRouter();
  let response ={
    source: null,
    data: null,
  };
  try {

    switch(api){
      case "apiweather":
        // Using api.weather.gov
        response.source = api;
        response.data = await getWfoNoaaApis(lon, lat, api);
        break;

      case "mapservices":
        // Using mapservices.weather.noaa
        response.source = api;
        response.data = await getWfoNoaaApis(lon, lat, api);
        break;
    }

    if(response.data == null){
      response.source = null;
    }

    // console.debug(`getForecastOffice: ${JSON.stringify(response)}`);

    // if(response.data == null){
    //   errorInfoStore.generalMessage = `Unable to get the forecast office from
    //     the weather API. Please submit a ticket including the response code
    //     and text below as well as the current time ${new Date()}.`;
    //   errorInfoStore.responseCode = response.status;
    //   errorInfoStore.responseText = response.statusText;
    //   router.push({ name: "errorPage" });
    // }

    return response;

  } catch (err) {
    console.error(err.message);
  }
}

export async function getFireZoneOffice(fireWeatherZoneUrl) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(
      `Getting fire weather office from URL: ${fireWeatherZoneUrl}`
    );
    // Make sure we have a URL to hit
    if (fireWeatherZoneUrl !== "") {
      // Call the API endpoint to retrieve the associated fire zone site id
      const response = await fetch(fireWeatherZoneUrl, apiWeatherOptionsGet);
      if (response.status == 200) {
        return await response.json();
      } else {
        errorInfoStore.generalMessage = `Unable to retrieve the fire zone office
          from ${fireWeatherZoneUrl}. Please submit a ticket including the
          response code and text below as well as the current time ${new Date()}.`;
        errorInfoStore.responseCode = response.status;
        errorInfoStore.responseText = response.statusText;
        router.push({ name: "errorPage" });
      }
    }
  } catch (err) {
    console.error(err.message);
  }
  // Return an empty string on error or empty URL passed in
  return "";
}

export async function getTimezones() {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(`getTimezones`);
    const response = await fetch(`${API_URL}/timezones`, defaultOptionsGET); // eslint-disable-line no-undef
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve the available time
        zones from the API. Please submit a ticket including the response
        code and text below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getOfficeIdByNativeId(nativeSiteId) {
  // https://spot-dev-api.ncep.noaa.gov/offices?nativeSiteId=LBF
  try {
    console.debug(`getOfficeByNativeId: Site id = ${nativeSiteId}`);
    const response = await fetch(
      `${API_URL}/offices?nativeSiteId=${nativeSiteId}`, // eslint-disable-line no-undef
      defaultOptionsGET
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      throw `Unable to retrieve the native site id for ${nativeSiteId}`;
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getSingleForecast(id, page = 1) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(`getSingleForecast with id: ${id}`);
    const response = await fetch(
      `${API_URL}/forecasts?spotRequest.id=${id}&page=${page}`, // eslint-disable-line no-undef
      defaultOptionsGET
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve the forecast with
        id ${id} from the API. Please submit a ticket including the response
        code and text below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getForecastCountByDate() {
  const errorInfoStore = useErrorInfoStore();
  try {
    console.debug(`getForecastCountByDate`);
    const response = await fetch(
      `${API_URL}/forecasts/20300101/20200101`, // eslint-disable-line no-undef
      defaultOptionsGET
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve the forecast count
        from the API. Please submit a ticket including the response code and
        text below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      /*
       * Since this method is called before the router is setup, just return
       * false. The calendar will check for false and send the user to the
       * error page. Fill the error store though as it will have all the
       * info to display on the error page.
       */
      return false;
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getForecastsByDate(date, isArchived) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  const currentDate = new Date(date);
  const next = currentDate.setDate(currentDate.getDate() + 1);
  const nextDate = new Date(next).toISOString().replace(/T.*$/, "");
  try {
    console.debug(
      `getForecastsByDate before date ${nextDate} and delivered after ${date}`
    );
    const response = await fetch(
      `${API_URL}/requests?isArchived=${isArchived}&page=1&deliverAt%5Bstrictly_before%5D=${nextDate}&deliverAt%5Bafter%5D=${date}`, // eslint-disable-line no-undef
      defaultOptionsGET
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve forecasts after
        ${date} and before ${nextDate} from the API. Please submit a ticket
        including the response code and text below as well as the current
        time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getRequestById(requestId) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(`getRequestById - request id: ${requestId}`);
    let requestUrl = `${API_URL}/requests`; // eslint-disable-line no-undef
    if (requestId !== "") {
      requestUrl = `${requestUrl}/${requestId}`;
    }
    const response = await fetch(requestUrl, defaultOptionsGET);
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve SPOT request for id
        ${requestId} from the API. Please submit a ticket including the response
        code and text below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getSpotOptions() {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(`getSpotOptions`);
    const response = await fetch(`${API_URL}/options`, defaultOptionsGET); // eslint-disable-line no-undef
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve SPOT site options
        from the API. Please submit a ticket including the response code and
        text below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getForecastIncidentInfo(incidentId, officeId) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(
      `getForecastIncidentInfo - incident id: ${incidentId}, office id: ${officeId}`
    );
    const response = await fetch(
      `${API_URL}/office-configs?incident.id=${incidentId}&office.id=${officeId}`, // eslint-disable-line no-undef
      defaultOptionsGET
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve forecast for incident
        ${incidentId} and office ${officeId} from the API. Please submit a
        ticket including the response code and text below as well as the current
        time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getOfficeIncidentElements(incidentId, officeId) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(
      `getOfficeIncidentElements - incident id: ${incidentId}, office id: ${officeId}`
    );
    const response = await fetch(
      `${API_URL}/office-elements?incident.id=${incidentId}&office.id=${officeId}`, // eslint-disable-line no-undef
      defaultOptionsGET
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve office elements for
        incident ${incidentId} and office ${officeId} from the API. Please
        submit a ticket including the response code and text below as well as
        the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getSpotObservations(spotId) {
  const errorInfoStore = useErrorInfoStore();
  const router = useRouter();
  try {
    console.debug(`getSpotObservations for id: ${spotId}`);
    const response = await fetch(
      `${API_URL}/observations?page=1&spotRequest=${spotId}`, // eslint-disable-line no-undef
      defaultOptionsGET
    );
    if (response.status == 200) {
      return await response.json();
    } else {
      errorInfoStore.generalMessage = `Unable to retrieve observations for SPOT
        id ${spotId} from the API. Please submit a ticket including the response
        code and text below as well as the current time ${new Date()}.`;
      errorInfoStore.responseCode = response.status;
      errorInfoStore.responseText = response.statusText;
      router.push({ name: "errorPage" });
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function submitNewObservation(data) {
  /*
   * SPOT-65: Ensure an elevation is in the observation data as it is marked as
   * required in the DB, but not essential for an observation on the front end.
   */
  if (data.elevation == undefined) {
    data.elevation = 0;
  }
  const options = {
    ...defaultOptionsPost,
    body: JSON.stringify(data),
  };
  try {
    console.debug(`submitNewObservation: ${options.body}`);
    let response = await fetch(`${API_URL}/observations`, options); // eslint-disable-line no-undef
    if (response.status == 200) {
      return await response.json();
    } else {
      return await response;
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function submitForecastFeedback(data) {
  const options = {
    ...defaultOptionsPUT,
    body: JSON.stringify(data),
  };
  try {
    const id = data.id;
    console.debug(`submitNewForecastFeedback: ${options.body}`);
    let response = await fetch(`${API_URL}/forecasts/${id}`, options); // eslint-disable-line no-undef
    if (response.status == 200) {
      return await response.json();
    } else {
      return await response;
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function getElevation(latitude, longitude) {
  try {
    console.info(`Get elevation at lon,lat = ${longitude}, ${latitude}`);
    const response = await fetch(
      `${ELEVATION_URL}?x=${longitude}&y=${latitude}&units=Feet&wkid=4326&includeDate=False`, // eslint-disable-line no-undef
      apiWeatherOptionsGet
    );
    if (response.status == 200) {
      try {
        const elevationData = await response.json();
        return await elevationData;
      } catch (err){
        throw new Error(`lon,lat: ${longitude}, ${latitude} at sea level.`);
      }

    } else {
      throw new Error(`Unable to get elevation for lon/lat: ${longitude}, ${latitude}.`);
    }
  } catch (err) {
    console.error(err.message);
  }
}

export async function fetchArcgisDataByStreetAddress(streetAddressString) {
  console.log(streetAddressString)
  const url = `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/find/?callback=jQuery16304427590768340044_1683748340977&sourceCountry=USA&text=${streetAddressString.replace(
    " ",
    "+"
  )}&maxLocations=1&f=json&returnGeometry=true&returnZ=true&_=1683748359880`;
  let response = await fetch(url);
  let data = await response.text();
  return toRaw(
    JSON.parse(data.split("(", 2)[1].slice(0, -2))
  );
}
