<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>
<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <!-- Modal -->
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                Incident and Decision Support Forecast Request
              </h1>
            </div>
          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div>NOTICE</div>
            <p>
              This interface is intended to be used solely for the relay of
              forecast information to the National Weather Service. Submissions
              sent through this online form are intended for internal agency
              use.
            </p>
            <p>
              We are required (by e-Gov Act of 2002) to explicitly state that
              submission of any information is voluntary. For further
              information please read our
              <a href="http://www.weather.gov/privacy.php" target="_blank">Privacy Policy</a>
              and
              <a href="http://www.nws.noaa.gov/disclaimer.php" target="_blank">Disclaimer</a>.
            </p>
            <p>
              False statements on this form may be subject to prosecution under
              the False Statement Accountability Act of 1996 (18 U.S.C. § 1001)
              or other statutes.
            </p>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button class="btn btn-primary" @click="$emit('close')">
              Understood
            </button>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  /* max-width: 50%; */
  margin: auto 1em;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  flex-shrink: inherit !important;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (min-width: 1000px) {
  .modal-container {
    max-width: 60%;
    margin: auto;
  }
}


</style>
