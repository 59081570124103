<script>
import Footer from "@/components/NOAAFooter.vue";
import Header from "@/components/NOAAHeader.vue";
import DisclaimerModal from "@/components/DisclaimerModal.vue";
import Cookies from "js-cookie";
import { useOptionsStore } from "@/stores/optionsStore.js";
import { onMounted } from "vue";

export default {
  name: "App",
  components: {
    Header,
    DisclaimerModal,
    Footer,
  },
  created() {
    window.onload = () => {
      var v = Cookies.get("visits");
      if (v == undefined) {
        Cookies.set("visits", 1, { expires: 30 });
        Cookies.set("entryPage", document.location.href, { expires: 30 });
        Cookies.set("entryDateTime", Date(), { expires: 30 });
        Cookies.set("cameFrom", document.referrer, { expires: 30 });
        this.showModal = true;
      }
    };
    console.debug(Cookies.get());
  },
  setup() {
    const optionsStore = useOptionsStore();
    onMounted(async () => {
      // Check is the option store is empty and load it if
      if (optionsStore.options.incidents.length === 0) {
        try {
          optionsStore.fillSpotOptions();
        } catch (err) {
          console.debug(err.message);
        }
      }
    });
    return {
      optionsStore,
    };
  },
  data() {
    return {
      showModal: false,
      activePage: [],
      pages: {},
    };
  },
};
</script>

<template>
  <Header></Header>
  <main>
    <router-view></router-view>
  </main>
  <Teleport to="body">
    <DisclaimerModal :show="showModal" @close="showModal = false">
    </DisclaimerModal>
  </Teleport>
  <Footer></Footer>
</template>
