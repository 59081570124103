import { defineStore } from "pinia";

export const useErrorInfoStore = defineStore("errorInfo", {
  state: () => ({
    generalMessage: "",
    responseCode: 0,
    responseText: "",
  }),
  actions: {
    resetErrorStore() {
      this.generalMessage = "";
      this.responseCode = 0;
      this.responseText = "";
    },
  },
});
