<script>
import NavBar from "@/components/NavBar/NavBar.vue";
export default {
  props: ["config"],
  components: {
    NavBar
  },
  data() {
    return {
      navbarConfig: {
        monitorItem: {
          name: "Monitor",
          type: "nav-bar-link",
          href: "/",
          icon: "bi bi-display pe-1",
        },
        calendarItem: {
          name: "Calendar",
          type: "nav-bar-link",
          href: "/calendar",
          icon: "bi bi-calendar3 pe-1",
        },
      },
    }
  }

};
</script>
<template>
  <header class="row sticky-top bg-white">
    <div class="col-12 px-0">
      <a href="http://www.noaa.gov/">
        <img class="float-start mx-md-2" :src="require('/src/assets/header_noaa.png')" alt="Noaa-Logo">
      </a>
      <a href="https://www.weather.gov/nws/">
        <img class="mx-md-2" :src="require('/src/assets/header_nws.png')" alt="Nation-Weather-Service-Logo">
      </a>
      <h3 class="d-inline-block d-md-none mb-1 ms-2 align-middle">
        <small>Spot Forecast<br class="d-sm-none"> Request</small>
      </h3>
      <h1 class="d-none d-md-inline-block mb-0 ms-2 align-middle text-center">Spot Forecast Request</h1>
      <img class="float-end" :src="require('/src/assets/header_doc.png')" alt="banner-header">
    </div>
    <nav class="navbar navbar-dark justify-content-end py-1">
      <div class="navbar-nav d-inline">
          <a class="nav-link d-inline-block pe-4" href="https://www.weather.gov/">
            <i class="bi bi-house-fill"></i><span class="ps-2">NWS Home</span>
          </a>
          <a class="nav-link d-inline-block pe-4" href="https://www.weather.gov/news/">
            <i class="bi bi-newspaper"></i><span class="ps-2">News</span>
          </a>
          <a class="nav-link d-inline-block pe-3" href="https://weather.gov/organization.php">
            <i class="bi bi-buildings-fill"></i><span class="ps-2">Org<span
                class="d-none d-sm-inline">anization</span></span>
          </a>
        </div>
    </nav>
    <NavBar alt="Spot Navbar" :config="navbarConfig" style="z-index: 1020;"></NavBar>
  </header>
</template>

<style scoped>
nav {
  background-color: #102290;

  nav .navbar-nav li a {
    color: white !important;
  }
}
</style>
