import { defineStore } from "pinia";
import { getSpotOptions } from "@/common/api.service";

export const useOptionsStore = defineStore("spotOptions", {
  state: () => ({
    options: {
      incidents: []
    },
  }),
  actions: {
    async fillSpotOptions() {
      this.options = await getSpotOptions();
    },
  },
});
