import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Monitor/Monitor.vue"),
    props: true,
  },
  { path: "/api/*" },
  {
    path: "/new-request",
    name: "request",
    component: () => import("@/views/Request/SpotRequests.vue"),
    props: true,
  },
  {
    path: "/observations/:spotId",
    name: "createObservation",
    component: () => import("@/views/Observation/NewObservation.vue"),
    props: true,
  },
  {
    path: "/new-request/details",
    name: "incident.details",
    component: () => import("@/views/Request/Details/IncidentDetails.vue"),
    props: true,
  },
  {
    path: "/request/edit/",
    name: "editRequest",
    component: () => import("@/views/Request/EditRequest.vue"),
    props: true,
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("@/views/Calendar/Calendar.vue"),
    props: true,
  },
  {
    path: "/forecasts/:id",
    name: "singleForecast",
    component: () => import("@/views/Forecasts/SingleForecast.vue"),
    props: true,
  },
  {
    path: "/forecasts/:date/:isArchived",
    name: "forecastsByDate",
    component: () => import("@/views/Calendar/ForecastsByDate.vue"),
    props: true,
  },
  {
    path: "/:wfo",
    name: "wfo",
    props: true,
    beforeEnter(wfo) {
      console.debug(wfo);
      window.open(`https://www.weather.gov${wfo.path}`);
    },
    component: () => import("@/views/Monitor/Monitor.vue"),
  },
  {
    path: "/error",
    name: "errorPage",
    component: () => import("@/views/ErrorPage.vue"),
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0, behavior: "smooth" }), 100);
      })
    );
    // return { top: null, left: null, behavior: null };
  },
});


export default router;
