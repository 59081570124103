
export function DateTimetoHuman(dateTime){
  let date = new Date(dateTime);
  // Date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero for single-digit months
  const day = String(date.getDate()).padStart(2, '0'); // Add leading zero for single-digit days
  const formattedDate = `${month}/${day}/${year}`;
  // Time
  const hours = String(date.getHours()).padStart(2,'0'); // Add leading zero for single-digit hours
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Add leading zero for single-digit minutes
  const formattedTime = `${hours}:${minutes}`;

  return `${formattedDate} - ${formattedTime}`;
}

export function getCurrentTimeAsString(dateOverride) {
  const currentDate = dateOverride || new Date();
  const hour = currentDate.toLocaleDateString("default", { hour: '2-digit' });
  const minute = currentDate.toLocaleDateString("default", { month: '2-digit' })
  return `${hour}:${minute}`
}

/*
 * SPOT-170: Added new parameter leaveLeading. The future forecast date/time
 * input needs the leading zero in order to load correctly. Default to false
 * so other methods do not need to be updated.
 */
export function  parsedTime(time, leaveLeading = false) {
  let parsed = time.split("T")[1].split("+")[0].substring(0, 5)
  if (leaveLeading === false) {
    return parsed.charAt(0) === "0" ? parsed.slice(1) : parsed
  }
  return parsed;
}

export function changeTimezone(ianatz) {
  const currentTime = new Date(Date.now());
  const tzTime = new Date(currentTime.toLocaleString('en-US', { timeZone: ianatz }));
  return `${tzTime.getHours()}:${tzTime.toJSON().split(":", 2)[1]}`;
}

export function getCurrentDateAsString(dateOverride) {
  const currentDate = dateOverride || new Date();
  const year = currentDate.toLocaleDateString("default", { year: 'numeric' });
  const month = currentDate.toLocaleDateString("default", { month: '2-digit' })
  const day = currentDate.toLocaleDateString("default", { day: '2-digit' })
  return `${year}-${month}-${day}`
}

export function getCurrentDateTimeAsString() {
  const currentDate = new Date();
  return `${getCurrentDateAsString(currentDate)} ${getCurrentTimeAsString(currentDate)}`
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  // Then specify how you want your dates to be formatted
  return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(date);
}
export function currentHour(dateString) {
  const date = new Date(dateString);
  return date.getHours();
}
export function getNextDay(dateString) {
  const date = new Date(dateString);
  const next = date.setDate(date.getDate() + 1);
  return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(next);
}
export function getNextNextDay(dateString) {
  const date = new Date(dateString);
  const next = date.setDate(date.getDate() + 2);
  return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(next);
}

const MINUTE = 60000;
const HOUR = MINUTE * 60;

export const isDayTime = (dateTime = null) => {
  if (!dateTime) {
    dateTime = new Date(Date.now());
  }
  return dateTime.getHours() >= 0 && dateTime.getHours() < 18;
};

export const isNightTime = (dateTime) => {
  return !isDayTime(dateTime);
};

export const getNextPeriod = (dateTime = null) => {
  if (!dateTime) {
    dateTime = new Date(Date.now());
  }
  let hour = isDayTime(dateTime) ? 6 : 18;
  let normalized =
    new Date(
      dateTime.getFullYear(),
      dateTime.getMonth(),
      dateTime.getDate(),
      hour,
      0,
      0,
      0
    ).valueOf() +
    HOUR * 12;
  return new Date(normalized);
};

export const generateDates = (dateTime = null, periods = 4) => {
  if (!dateTime) {
    dateTime = new Date(Date.now());
  }
  let result = [dateTime];
  let next = getNextPeriod(dateTime);
  result.push(next);
  for (let i = 0; i < periods - 1; i++) {
    next = getNextPeriod(next);
    result.push(next);
  }
  return result;
};

export class DateTimeInput {
  constructor(date = null, time = null) {
    this.date = date ? date : this.defaultDate();
    this.time = time ? time : this.defaultTime();
  }
  defaultDate() {
    let dateTime = new Date(Date.now());
    return `${dateTime.getFullYear()}-${("0" + (dateTime.getMonth() + 1)).slice(
      -2
    )}-${("0" + dateTime.getDate()).slice(-2)}`;
  }
  defaultTime() {
    let dateTime = new Date(Date.now());
    /*
     * Add 1 to the current hour as the first time available in the drop downs
     * starts with the next hour.
     */
    return `${(dateTime.getHours() + 1)}:00`;
  }
  value() {
    return `${this.date} ${this.time}`;
  }

  epoch() {
    return Date.parse(this.value());
  }

  copy(dateTimeInput) {
    this.date = dateTimeInput.date;
    this.time = dateTimeInput.time;
  }
}
