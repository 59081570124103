<script>
export default {
  props: {
    name: String,
    items: Array 
  }
}
</script>

<template>
  <div class="d-none d-sm-inline-block">
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle text-body" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ this.name }}
      </a>
      <ul class="dropdown-menu">
        <li v-for="(item, index) in this.items" :key="{ index }">
          <a class="dropdown-item" :href="item.href">{{ item.name }}</a>
        </li>
      </ul>
    </li>
    </div>
</template>
