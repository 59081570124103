import { createApp } from "vue";
import App from "./App.vue";
import "../public/main.css";
import router from "./router/index.js";
import AppLink from "@/components/AppLink.vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

// Import Bootstrap
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.component("AppLink", AppLink).use(pinia).use(router).mount("#app");
