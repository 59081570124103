import { defineStore } from "pinia";
import { getOfficeIdByNativeId } from "@/common/api.service";
import { getCurrentDateTimeAsString, DateTimeInput } from "@/common/time";

export const useRequestStore = defineStore("request", {
  state: () => ({
    actionStatus: "ST_STARTRQST",
    aspect: "",
    bottomElevation: 0,
    currentUpdate: 0,
    coordinates: [0, 0],
    initialCoordinates: [-100, 41],
    deliverAt: new DateTimeInput(),
    draftForecast: "",
    drainage: "",
    fireZoneOfficeId: "",
    fireZoneSiteId: "LBF",
    fuelType: "",
    futureForecastSchedules: [],
    incident: {},
    incidentPFReason: "",
    isDaylightSavings: true,
    isDispatched: false,
    isEditing: false,
    isHysplit: "No",
    observations: [],
    office: "",
    projectName: "",
    quad: "",
    question: "",
    remarks: "",
    requestElements: [],
    requestFormats: [],
    requesterAgency: "",
    requesterEmail: "",
    requesterFax: "",
    requesterMeta: navigator.userAgent,
    requesterName: "",
    requesterOfficial: "",
    requesterPhone: "",
    requesterPhoneExt: "",
    sheltering: "",
    siteId: "",
    size: 0,
    spotForecasts: [],
    startedAt: new DateTimeInput(),
    state: "",
    submittedAt: getCurrentDateTimeAsString(),
    Timezone: {},
    topElevation: 0,
    viewableIncidents: [],
    zoom: 4.5,
  }),
  persist: {
    storage: sessionStorage,
    paths: [
      "aspect",
      "bottomElevation",
      "drainage",
      "fireZoneSiteId",
      "fuelType",
      "futureForecastSchedules",
      "incident",
      "incidentPFReason",
      "isEditing",
      "isHysplit",
      "latitude",
      "longitude",
      "observations",
      "office",
      "projectName",
      "quad",
      "question",
      "remarks",
      "requestElements",
      "requestFormats",
      "requesterAgency",
      "requesterOfficial",
      "requesterEmail",
      "requesterPhone",
      "requesterPhoneExt",
      "requesterFax",
      "requesterName",
      "siteId",
      "sheltering",
      "size",
      "state",
      "Timezone",
      "topElevation",
    ],
  },
  getters: {
    longitude() {
      return Number(this.coordinates[0].toFixed(4));
    },
    latitude() {
      return Number(this.coordinates[1].toFixed(4));
    },
    elevation() {
      return this.topElevation;
    }

  },
  actions: {

    setOfficeId(id) {
      this.office = id;
    },
    setIncidentId(id) {
      console.debug(`setting id ${id}`);
      this.incidentId = `/incidents/${id}`;
    },
    async getOfficeId(nativeSiteId) {
      return getOfficeIdByNativeId(nativeSiteId);
    },
    setForecastFormat(format) {
      this.forecastFormat = format.substring(1, -1);
    },

    isValid() {
      /*
       * I could only find one article somewhat relevant to using a pinia store along with
       * vuelidate. I don't know how to get those 2 to work together, so for now I put in
       * this method to ensure all the required data set before enabling the submit button.
       * This will at least for now resolve this issue as the deadline closes in.
       */
      if (this.projectName.length < 3) {
        return false;
      }
      if (this.requesterAgency.length < 2) {
        return false;
      }
      if (this.requesterOfficial.length < 2) {
        return false;
      }
      if (this.requesterEmail !== "") {
        const validEmail = String(this.requesterEmail)
          .toLowerCase()
          .match(
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/i
          );
        if (validEmail === false) {
          return validEmail;
        }
      }
      if (this.requesterPhone !== "") {
        const validPhone = String(this.requesterPhone)
          .toLowerCase()
          .match(/^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/i);
        if (validPhone === false) {
          return validPhone;
        }
      }
      if (
        // Validete category and size, aspect, fuelType, sheltering
        this.incident.category !== undefined &&
        this.incident.category.toLowerCase().includes("fire") === true &&
        Number(this.size).valueOf() < 0
      ) {
        return false;
      }
      if (
        this.incident.category !== undefined &&
        this.incident.category.toLowerCase().includes("fire") === true &&
        this.aspect.length < 1
      ) {
        return false;
      }
      if (
        this.incident.category !== undefined &&
        this.incident.category.toLowerCase().includes("fire") === true &&
        this.fuelType.length < 2
      ) {
        return false;
      }
      if (
        this.incident.category !== undefined &&
        this.incident.category.toLowerCase().includes("fire") === true &&
        this.sheltering === ""
      ) {
        return false;
      }
      return true;
    },
  },
});
