<template>
	<footer class="row row-cols-3 row-cols-md-4 mt-4 py-3 text-center">
    <div class="d-none d-md-block col">
      <img :src="require('/src/assets/usa_gov.png')" alt="USA gov logo" />
    </div>
    <div class="col">
      <img class="mb-2 d-md-none" :src="require('/src/assets/usa_gov.png')" alt="USA gov logo" />
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><small>
          <a href="http://www.commerce.gov/" class="nav-link p-0 text-muted" title="US Department of Commerce" tabindex="-1">
            US <span class="d-md-none">DOC</span>
            <span class="d-none d-md-inline-block">Dept of Commerce</span>
          </a>
        </small></li>
        <li class="nav-item mb-2"><small>
          <a href="http://www.noaa.gov/" class="nav-link p-0 text-muted" title="National Oceanic and Atmospheric Administration" tabindex="-1">
            <span class="d-lg-none">NOAA</span>
            <span class="d-none d-lg-inline-block">National Oceanic and Atmospheric Administration</span>
          </a>
        </small></li>
        <li class="nav-item"><small>
          <a href="http://www.weather.gov/" class="nav-link p-0 text-muted" title="National Weather Service" tabindex="-1">
            <span class="d-md-none">NWS</span>
            <span class="d-none d-md-inline-block">National Weather Service</span>
          </a>
        </small></li>
        <address class="d-none d-md-block text-muted"><small>
          1325 East West Highway<br>Silver Spring, MD 20910
        </small></address>
      </ul>
    </div>
    <div class="col">
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><small>
          <a href="http://www.nws.noaa.gov/disclaimer.php" class="nav-link p-0 text-muted" tabindex="-1">Disclaimer</a>
        </small></li>
        <li class="nav-item mb-2"><small>
          <a href="http://www.weather.gov/credits.php" class="nav-link p-0 text-muted" tabindex="-1">Credits</a>
        </small></li>
        <li class="nav-item mb-2"><small>
          <a href="http://www.weather.gov/glossary/" class="nav-link p-0 text-muted" tabindex="-1">Glossary</a>
        </small></li>
        <li class="nav-item"><small>
          <a href="https://www.weather.gov/Contact" class="nav-link p-0 text-muted" tabindex="-1">Contact Us</a>
        </small></li>
      </ul>
    </div>
    <div class="col">
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><small>
          <a href="http://www.weather.gov/privacy.php" class="nav-link p-0 text-muted" tabindex="-1">Privacy Policy</a>
        </small></li>
        <li class="nav-item mb-2"><small>
          <a href="https://www.noaa.gov/foia-freedom-of-information-act" class="nav-link p-0 text-muted" tabindex="-1">
            <span class="d-lg-none">FOIA</span>
            <span class="d-none d-lg-inline">Freedom of Information Act (FOIA)</span>
          </a>
        </small></li>
        <li class="nav-item mb-2"><small>
          <a href="http://www.weather.gov/admin.php" class="nav-link p-0 text-muted" tabindex="-1">About Us</a>
        </small></li>
        <li class="nav-item"><small>
          <a href="http://www.weather.gov/careers.php" class="nav-link p-0 text-muted" tabindex="-1">Career Opportunities</a>
        </small></li>
      </ul>
    </div>
    <div class="col-12 pt-2 d-md-none">
      <address class="text-muted"><small>
        1325 East West Highway Silver Spring, MD 20910
      </small></address>
		</div>
	</footer>
</template>

<style scoped>
footer {
  background-color: #c5e5f5;
}
</style>